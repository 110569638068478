import React, { Component, Fragment } from 'react';
import { GeneralDeviceState } from '../general/redux/types';
import { TFunction } from 'i18next';
import DateUtils from 'core/utils/date-utils';

interface AccountSecurityProps {
  calls: (id: string, fetchAll?: boolean, settingsCall?: boolean) => void;
  general: GeneralDeviceState;
  settings: any;
  id: string;
  t: TFunction;
}

const AccountSecurityComponent: React.FunctionComponent<AccountSecurityProps> = (props) => {

  React.useEffect(() => {
    const { id, calls, general } = props;
    calls(id, false, false);
  }, [JSON.stringify(props.general.shadow)])

  const getUserGroup = (configurations: any) => {
    const foundItem: any = []
    const { t } = props

    if (configurations && configurations.length > 0) {
      configurations.map((item: any) => {
        if (item.component === 'account_security') foundItem.push(item)
      })
    }

    if (foundItem.length > 0 && foundItem[0].fields[0].currentValue[0]?.label && foundItem[0].fields[0].currentValue !== "") {
      return foundItem[0].fields[0].currentValue[0]?.label
    }
    else {
      return t('general.not_available')
    }
  }


  const accountProperties = (shadow: any, t: TFunction) => {
    return (
      <Fragment>
        <h5 className="title is-size-5">{t('account.subtitle_account')}</h5>
        <p className="text-wrap has-margin-bottom-sm">
          <span className="has-margin-right-sm">HHID:</span>
          {shadow && shadow.householdID
            ? shadow.householdID
            : t('general.not_available')}
        </p>
        <p className="has-margin-bottom-sm">
          <span className="has-margin-right-sm">{`${t(`account.label_region`)}:`}</span>
          {shadow &&
            shadow.reported &&
            shadow.reported.account &&
            shadow.reported.account.regID &&
            shadow.reported.account.regID !== ''
            ? shadow.reported.account.regID
            : t('general.not_available')}
        </p>
        <p className="has-margin-bottom-sm">
          <span className="has-margin-right-sm">{`${t(`account.label_internal_region_id`)}:`}</span>
          {shadow &&
            shadow.custom &&
            shadow.custom.REGIDBE !== ''
            ? shadow.custom.REGIDBE
            : t('general.not_available')}
        </p>
        <p className="has-margin-bottom-sm">
          <span className="has-margin-right-sm">{`${t(`account.label_regionalization_mode`)}:`}</span>
          {shadow &&
            shadow.custom &&
            shadow.custom.REGSET !== ''
            ? shadow.custom.REGSET
            : t('general.not_available')}
        </p>

        <p className="has-margin-bottom-sm">
          <span className="has-margin-right-sm">{`${t(`account.label_user_group`)}:`}</span>
          {shadow &&
            shadow.reported &&
            shadow.reported.config && shadow.reported.config.usageID !== 'Not available'
            ? getUserGroup(props.settings.configurations)
            : t('general.not_available')}
        </p>

        <p className="has-margin-bottom-sm">
          <span className="has-margin-right-sm">{`${t(`account.label_customer_type`)}:`}</span>
          {shadow &&
            shadow.reported &&
            shadow.reported.account &&
            shadow.reported.account.cType &&
            shadow.reported.account.cType !== ''
            ? shadow.reported.account.cType
            : t('general.not_available')}
        </p>
      </Fragment>
    );
  };

  const securityProperties = (shadow: any, t: TFunction) => (
    <Fragment>
      <h5 className="title is-size-5">{t('account.subtitle_security')}</h5>
      <p className="has-margin-bottom-sm">
        <span className="has-margin-right-sm">{`${t(`account.label_ca_id`)}:`}</span>
        {shadow &&
          shadow.reported &&
          shadow.reported.security &&
          shadow.reported.security.cas.ID &&
          shadow.reported.security.cas.ID !== ''
          ? shadow.reported.security.cas.ID
          : t('general.not_available')}
      </p>

      <p className="has-margin-bottom-sm">
        <span className="has-margin-right-sm">{`${t(`account.label_ca_name`)}:`}</span>
        {shadow &&
          shadow.reported &&
          shadow.reported.security &&
          shadow.reported.security.cas.name &&
          shadow.reported.security.cas.name !== ''
          ? shadow.reported.security.cas.name
          : t('general.not_available')}
      </p>

      <p className="has-margin-bottom-sm">
        <span className="has-margin-right-sm">{`${t(`account.label_ca_version`)}:`}</span>
        {shadow &&
          shadow.reported &&
          shadow.reported.security &&
          shadow.reported.security.cas.ver &&
          shadow.reported.security.cas.ver !== ''
          ? shadow.reported.security.cas.ver
          : t('general.not_available')}
      </p>

      <p className="has-margin-bottom-sm">
        <span className="has-margin-right-sm">{`${t(`account.label_ca_status`)}:`}</span>
        {shadow &&
          shadow.custom &&
          (shadow.custom.NGRS || shadow.custom.NGRS === '') && shadow.custom.NGRS != 'Not available'
          ? getCAStatus(shadow.custom.NGRS, t)
          : t('general.not_available')}
      </p>
    </Fragment>
  );


  const { general, id, t } = props;

  return (
    <div className="tile is-child box has-padding-lg flex-zero">
      <div className="columns text-light text-size-normal">
        <div className="column is-6 has-padding-left-none">
          {general && accountProperties(general.shadow, t)}
        </div>
        <div className="column is-6 has-padding-left-none">
          {general && securityProperties(general.shadow, t)}
        </div>
      </div>
    </div>
  );
}
export default AccountSecurityComponent;

const getCAStatus = (customNGRS: string, t: TFunction) => {
  if (customNGRS === 'true') {
    return t('account.security.ca_status.enabled');
  } else if (customNGRS === 'false') {
    return t('account.security.ca_status.error');
  } else if (customNGRS === ''){
    return t('account.security.ca_status.not_enabled');
  }
  else{
    return t('general.not_available');
  }
};