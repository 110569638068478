export const GENERAL_BUCKET = 'GENERAL_BUCKET';
export const GENERAL_BUCKET_SUCCESS = 'GENERAL_BUCKET_SUCCESS';
export const GENERAL_BUCKET_FAILURE = 'GENERAL_BUCKET_FAILURE';
export const GENERAL_SHADOW = 'GENERAL_SHADOW';
export const GENERAL_SHADOW_SUCCESS = 'GENERAL_SHADOW_SUCCESS';
export const GENERAL_SHADOW_FAILURE = 'GENERAL_SHADOW_FAILURE';
export const GENERAL_VIP = 'GENERAL_VIP';
export const GENERAL_VIP_SUCCESS = 'GENERAL_VIP_SUCCESS';
export const GENERAL_VIP_FAILURE = 'GENERAL_VIP_FAILURE';
export const GENERAL_ID_MAPPING = 'GENERAL_ID_MAPPING';
export const GENERAL_ID_MAPPING_SUCCESS = 'GENERAL_ID_MAPPING_SUCCESS';
export const GENERAL_ID_MAPPING_FAILURE = 'GENERAL_ID_MAPPING_FAILURE';
export const GENERAL_CLEAR_CACHE = 'GENERAL_CLEAR_CACHE';
export const DEVICE_OS = {
  ANDROID: 'android',
  LINUX: 'linux',
  HORIZON: 'horizon',
}

export type GENERAL_BUCKET = typeof GENERAL_BUCKET;
export type GENERAL_BUCKET_SUCCESS = typeof GENERAL_BUCKET_SUCCESS;
export type GENERAL_BUCKET_FAILURE = typeof GENERAL_BUCKET_FAILURE;
export type GENERAL_SHADOW = typeof GENERAL_SHADOW_SUCCESS;
export type GENERAL_SHADOW_SUCCESS = typeof GENERAL_SHADOW_SUCCESS;
export type GENERAL_SHADOW_FAILURE = typeof GENERAL_SHADOW_FAILURE;
export type GENERAL_VIP = typeof GENERAL_VIP;
export type GENERAL_VIP_SUCCESS = typeof GENERAL_VIP_SUCCESS;
export type GENERAL_VIP_FAILURE = typeof GENERAL_VIP_FAILURE;
export type GENERAL_ID_MAPPING = typeof GENERAL_ID_MAPPING;
export type GENERAL_ID_MAPPING_SUCCESS = typeof GENERAL_ID_MAPPING_SUCCESS;
export type GENERAL_ID_MAPPING_FAILURE = typeof GENERAL_ID_MAPPING_FAILURE;
export type GENERAL_CLEAR_CACHE = typeof GENERAL_CLEAR_CACHE;

export interface DeviceDetailOverviewAction {
  type:
    | GENERAL_BUCKET_SUCCESS
    | GENERAL_BUCKET_FAILURE
    | GENERAL_SHADOW_SUCCESS
    | GENERAL_SHADOW_FAILURE
    | GENERAL_VIP_SUCCESS
    | GENERAL_VIP_FAILURE
    | GENERAL_ID_MAPPING_SUCCESS
    | GENERAL_ID_MAPPING_FAILURE
    | GENERAL_CLEAR_CACHE;
  payload?: any;
}

export interface DeviceOverviewIdentifiers {
  serialNo: string;
  model: string;
  brand: string;
  bootT: string;
}

export interface GeneralDeviceState {
  shadow?: DeviceShadow;
  vip?: VipDeviceDetail;
  idMapping?: any;
  bucket?: Array<DeviceImage>;
}

export interface DeviceImage {
  bucketName: string;
  fileName: string;
  createdDate: string;
  file: string;
}
export interface VipDeviceDetail {
  amuse: string;
  asuiver: string;
  audds: string;
  auout: string;
  brnme: string;
  cal: string;
  cgmac: string;
  cpulv: string;
  cputp: string;
  device_dt: string;
  device_id: string;
  device_ts: string;
  dlang: string;
  dmfre: string;
  dmuse: string;
  dttch: string;
  dttci: string;
  dvbc: string;
  dvsd: string;
  entt: string;
  envrm: string;
  epgv: string;
  event_ts: string;
  eventgroup_id: string;
  eventtype_id: string;
  hash: string;
  hdcp: string;
  hddpnr: string;
  hddsn: string;
  hddst: string;
  hddtp: string;
  hddts: string;
  hddus: string;
  hdmic: string;
  hdmid: any;
  hdmiMode: string;
  hdmir: string;
  household_id: string;
  hrres: string;
  hwv: string;
  ipmsk: string;
  ledbr: string;
  lockch: string;
  macet: string;
  mcast: string;
  mn: string;
  netcg: string;
  ngrs: string;
  ntype: string;
  opco_id: string;
  opcoid: string;
  pin: string;
  powsm: string;
  prdns: string;
  prvip: string;
  pvsd: string;
  rpair: string;
  rcurn: string;
  scdns: string;
  sn: string;
  ssid: string;
  stbmd: string;
  subst: string;
  swupd: string;
  swv: string;
  ua: string;
  user_id: string;
  vltd: string;
  wfchn: string;
  wffrq: string;
  wfmac: string;
  wfsig: string;
  previousValues: {
    hdmir: string;
    hdcp: string;
    cal: string;
  };
  hddsmart: Array<VipDeviceDetailSmart>;
}

export interface ThreshholdSmart {
  atributeName: string;
  id: string;
  thresh: string;
}

export interface VipDeviceDetailSmart {
  id: string;
  flag: string;
  value: string;
  worst: string;
  raw: string;
  name: string;
}
export interface DeviceShadow {
  reported: {
    account: {
      HHID: string;
      cType: string;
      regID: string;
    };
    checksum: string;
    config: {
      GDPRCsV: string;
      HDMIAM: string;
      HDMIM: string;
      HDMIR: string;
      RCM: string;
      TVCtrl: string;
      UILg: string;
      actStByT: string;
      conDev: string;
      hearImpSu: string;
      nType: string;
      pAuLg: string;
      pRatg: string;
      pSuLg: string;
      pSvM: string;
      sAuLg: string;
      sSuLg: string;
      standBy: string;
      subs: string;
      unSuCh: string;
      usageID: string;
      visImpAu: string;
    };
    hardware: {
      SN: string;
      bootT: string;
      brn: string;
      mod: string;
    };
    security: {
      cas: {
        ID: string;
        name: string;
        ver: string;
      };
      drm: drm[];
    };
    software: {
      RCVer: string;
      UI: string;
      ver: string;
      os: string
    };
    status: {
      connected: string;
      connectedFor: string;
      lastConnectionDate: string;
    };
  };
  udid: string;
  custom: Custom;
  externalID: string;
  pCustom?: any;
  userConversion: UserConversion;
  rcglifecycleevents: RCGLifecycleEvents;
  userID: string;
  householdID: string;
  prevDeviceShadow: PrevDeviceShadow;
  domainExernalID: string;
  lastUpdateBy: string
  lastTechnicalDataEventDate: string
  lastUpdateDateUserConversion: number
  lastUpdateDateDeviceMonitoring: number
  lastUpdateDateRcgProvisioning: number
  lastUpdateDateRcgShadowUpdate: number
  auxProperties?: AuxProperties
}

export interface PrevDeviceShadow {
  config: {
    HDMIM: string;
  };
}

export interface UserConversion {
  channelName: string;
  pChannelName: string;
}

export interface drm {
  ID: string;
  name: string;
  ver: string;
}

export interface Custom {
  HDMID: {
    manufacturerName: string;
    hdmiModes: string;
  };
  CGMAC: string;
  HDMIC: string;
  PIN: string;
  CPULV: string;
  REGIDBE: string;
  REGSET: string;
  WanIP: string;
  HDCP: string;
  HDDST: string;
  HDDTP: string;
  HDDPNr: string;
  HDDSN: string;
  HDDTS: string;
  HDDUS: string;
  HWV: string;
  IPMsk: string;
  MACEt: string;
  NetCg: string;
  NType: string;
  PrDNS: string;
  PrvIP: string;
  RCURN: string;
  ScDNS: string;
  SwUpd: string;
  WFChn: string;
  WFFrq: string;
  WFMAC: string;
  WFSig: string;
  CAL: string;
  MUTE: string;
  CPUTp: string;
  BRNME: string;
  HDDSMART: Array<VipDeviceDetailSmart>;
  SSID: string;
  WFENC: string;
  NGRS: string;
  DVBC: Array<Array<string>>
  STBMD: string
  AUTHN: string
}

export interface pCustom {
  pHDCP: string;
  pHDMIR: string;
  pCal: string;
}
export interface RCGLifecycleEvents {
  sourceIp: string;
}

type AuxProperties = {
  isRCMConnected?: boolean
}
