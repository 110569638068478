import React from 'react'
import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import { dangerColor, defaultColor, iconSize, successColor } from '../../constants'
import { t } from 'i18next'
import { getDate } from '../../utils'

type ClientSectionProps = {
  lastUpdateBy: string
  lastClientRealTimeDataUpdate: number
  connected: boolean
  registeredInRCG: number
}

function getStatusColor(lastClientRealTimeDataUpdate: number, connected: boolean) {
  if(!lastClientRealTimeDataUpdate) {
    return defaultColor
  }

  return connected ? successColor : dangerColor
}

export default function ClientSection({ lastUpdateBy, lastClientRealTimeDataUpdate, connected, registeredInRCG }: ClientSectionProps) {
  return (
    <div className="section">
      <h3 className="section-title">{t('device_checks.labels_client')}</h3>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_client_last_update_data')}:</div>
        <div className="column is-6">{lastUpdateBy}</div>
      </div>
      <div className="divider"/>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_last_client_real_time_data_update')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={ getStatusColor(lastClientRealTimeDataUpdate, connected) }/>
            <span>{getDate(lastClientRealTimeDataUpdate)}</span>
          </div>
        </div>
      </div>
      <div className="divider"/>
      <div className="columns">
        <div className="column is-6">{t('account.label_registered_in_rcg')}:</div>
        <div className="column is-6">
          <span>{registeredInRCG ? getDate(registeredInRCG) : t('general.not_available')}</span>
        </div>
      </div>
      <div className="divider"/>
    </div>
  )
}
