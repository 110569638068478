import { SortedWifiEvents, WifiDashboardsAPIResponse, WifiState } from "./types"

const breakpointsMap = {
  3599000: '0',
  7199000: '- 1h',
  10799000: '- 2h',
  14399000: '- 3h',
  17999000: '- 4h',
  21599000: '- 5h',
  25199000: '- 6h',
  28799000: '- 7h',
  32399000: '- 8h',
  35999000: '- 9h',
  39599000: '- 10h',
  43199000: '- 11h',
  46799000: '- 12h',
  50399000: '- 13h',
  53999000: '- 14h',
  57599000: '- 15h',
  61199000: '- 16h',
  64799000: '- 17h',
  68399000: '- 18h',
  71999000: '- 19h',
  75599000: '- 20h',
  79199000: '- 21h',
  82799000: '- 22h',
  86399000: '- 23h',
  89999000: '- 24h',
} as any

/* Breakpoints -> hours range -> seconds range
0 -> 0:00h - 0:59h -> 0 - 3599
1 -> 1:00h - 1:59h -> 3600 - 7199
2 -> 2:00h - 2:59h -> 7200 - 10799
3 -> 3:00h - 3:59h -> 10800 - 14399
4 -> 4:00h - 4:59h -> 14400 - 17999
5 -> 5:00h - 5:59h -> 18000 - 21599
6 -> 6:00h - 6:59h -> 21600 - 25199
7 -> 7:00h - 7:59h -> 25200 - 28799
8 -> 8:00h - 8:59h -> 28800 - 32399
9 -> 9:00h - 9:59h -> 32400 - 35999
10 -> 10:00h - 10:59h -> 36000 - 39599
11 -> 11:00h - 11:59h -> 39600 - 43199
12 -> 12:00h - 12:59h -> 43200 - 46799
13 -> 13:00h - 13:59h -> 46800 - 50399
14 -> 14:00h - 14:59h -> 50400 - 53999
15 -> 15:00h - 15:59h -> 54000 - 57599
16 -> 16:00h - 16:59h -> 57600 - 61199
17 -> 17:00h - 17:59h -> 61200 - 64799
18 -> 18:00h - 18:59h -> 64800 - 68399
19 -> 19:00h - 19:59h -> 68400 - 71999
20 -> 20:00h - 20:59h -> 72000 - 75599
21 -> 21:00h - 21:59h -> 75600 - 79199
22 -> 22:00h - 22:59h -> 79200 - 82799
23 -> 23:00h - 23:59h -> 82800 - 86399
24 -> 24:00h - 24:59h -> 86400 - 89999
*/

function getBreakpoints() {
  return {
    3599000: undefined,
    7199000: undefined,
    10799000: undefined,
    14399000: undefined,
    17999000: undefined,
    21599000: undefined,
    25199000: undefined,
    28799000: undefined,
    32399000: undefined,
    35999000: undefined,
    39599000: undefined,
    43199000: undefined,
    46799000: undefined,
    50399000: undefined,
    53999000: undefined,
    57599000: undefined,
    61199000: undefined,
    64799000: undefined,
    68399000: undefined,
    71999000: undefined,
    75599000: undefined,
    79199000: undefined,
    82799000: undefined,
    86399000: undefined,
    89999000: undefined,
  } as SortedWifiEvents
}

export default function(events: WifiDashboardsAPIResponse): WifiState {
  const sortedEvents: SortedWifiEvents = {}
  const breakpoints = getBreakpoints()
  const now = new Date().getTime()
  let latestSignal = null
  let initialRange = 0

  for (const breakpoint in breakpoints) {
    const currentBreakpoint = Number(breakpoint)

    const event = events.wifiEvent.find(event => {
      const date = new Date(event.eventTs).getTime()
      const interval = (now - date)
      return interval < currentBreakpoint && interval > initialRange
    })

    sortedEvents[breakpointsMap[currentBreakpoint]] = event
    if(!latestSignal && event) {
      latestSignal = Number(event.signal)
    }

    initialRange = currentBreakpoint
  }

  return {
    events: sortedEvents,
    minSignal: events?.customWfSigMin ? Number(events.customWfSigMin) : null,
    maxSignal: events?.customWfSigMax ? Number(events.customWfSigMax) : null,
    latestSignal,
    score: events?.wifiScore ? Number(parseFloat(events.wifiScore).toFixed(1)) : null,
  }
}
