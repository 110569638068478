import React, { useRef, useState } from 'react'

export default function SignIn() {
  const [localStorageContent, setLocalStorageContent] = useState('')

  const handleRedirect = () => {
    const content = JSON.parse(localStorageContent)
    const keyValues = Object.keys(content)
    keyValues.forEach((keyToSet) => localStorage.setItem(keyToSet, content[keyToSet]))
    window.location.href = '/'
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleRedirect()
  }

  const onEnterPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(['Enter', 'NumpadEnter'].includes(event.key)) {
      event.preventDefault()
      handleRedirect()
    }
  }

  return (
    <div>
      <header>
        <h1 className='title'>Operation Console - Frontend</h1>
        <h3 className='subtitle'>To sign-in, follow the following steps:</h3>
      </header>
      <div>
        <ul>
          <li>
            Authenticate to <b><a href="https://oc.dev.vtvinsights.com/" target="_blank">dev environment</a></b>
          </li>
          <li>
            Open the <b>developer tools (F12)</b>
          </li>
          <li>Paste this command on dev environment console and run (press enter): <b>copy(localStorage)</b></li>
          <li>
            Paste the localStorage content on the <b>textarea</b> below
          </li>
          <li>
            Press the <b>login</b> button
          </li>
        </ul>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <textarea onChange={e => setLocalStorageContent(e.target.value)} onKeyDown={onEnterPress} rows={30} cols={150}/>
        </div>
        <input type="submit" />
      </form>
    </div>
  )
}
