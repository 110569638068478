import React from 'react'
import Topbar from '../../topbar/topbar-container'
import Card from 'core/card'
import { CardPaddings } from 'core/card/enum'
import ClientSection from './components/ClientSection'
import StatusSection from './components/StatusSection'
import './index.scss'
import { DeviceShadow, DEVICE_OS, GeneralDeviceState } from '../../general/redux/types'

type DeviceChecksProps = {
  id: string
  general: GeneralDeviceState
}

function getLastClientRealTimeUpdate(shadow?: DeviceShadow) {
  if (!shadow) return 0

  const {software, status} = shadow.reported
  if (software.os === 'android') {
    return parseInt(status.lastConnectionDate)
  }

  return shadow.lastUpdateDateRcgShadowUpdate
}

function getVIPConnectivityCheck(shadow?: DeviceShadow ) {
  if (!shadow) return 0


  return Math.max((shadow.lastUpdateDateUserConversion * 1000), (shadow.lastUpdateDateDeviceMonitoring * 1000))
}

function getLastUpdateBy(shadow: DeviceShadow | undefined) {
  if (shadow) {
    if(shadow.reported.software.os === DEVICE_OS.ANDROID && shadow.reported.status.connected === 'true') {
      return 'RADIX'
    }

    return shadow.lastUpdateBy
  }

  return ''
}

export default function DeviceChecksComponent({ id, general }: DeviceChecksProps) {
  const { shadow } = general

  return (
    <>
      <div className="columns width-full">
        <div className="column">
          <Topbar id={id} />
        </div>
      </div>
      <div className="tile is-vertical has-padding-left-none">
        <div className="tile is-parent is-vertical flex-zero has-padding-sm">
          <div className="tile is-child">
            <Card isFullHeight padding={CardPaddings.MEDIUM}>
              <ClientSection
                lastUpdateBy={getLastUpdateBy(shadow)}
                lastClientRealTimeDataUpdate={getLastClientRealTimeUpdate(shadow)}
                connected={shadow?.reported.status.connected === 'true'}
                registeredInRCG={shadow?.lastUpdateDateRcgProvisioning || 0}
              />
              <StatusSection
                VIPConnectivityCheck={getVIPConnectivityCheck(shadow)}
                STBMD={shadow?.custom.STBMD || ''}
                lastTechnicalDataEvent={shadow?.lastTechnicalDataEventDate || ''}
                clientDataBrokerCheck={shadow?.lastUpdateDateDeviceMonitoring || 0}
                authenticationTokenDate={shadow?.custom.AUTHN || ''}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
