import React from 'react'
import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import { dangerColor, iconSize, successColor, defaultColor, oneHourInMs, twentyMinutesInMs, warningcolor, eighteenHoursInMs, twentyTwoHoursInMs } from '../../constants'
import { t } from 'i18next'
import { getDate } from '../../utils'
import DateUtils from 'core/utils/date-utils'

type StatusSectionProps = {
  STBMD: string
  VIPConnectivityCheck: number
  lastTechnicalDataEvent: string
  clientDataBrokerCheck: number
  authenticationTokenDate: string
}

function getVIPConnectivityCheckStatusColor(VIPConnectivityCheck: number, STBMD: string, currentTime: number) {
  if(VIPConnectivityCheck) {
    const valuesToGreenStatus = ['Streaming', 'On UI', 'Active']
    if(valuesToGreenStatus.includes(STBMD)) {
      return successColor
    }

    const valuesToOrangeStatus = ['Active-Standby', 'Deep-standby', 'Standby']
    if(valuesToOrangeStatus.includes(STBMD)) {
      return warningcolor
    }

    if(currentTime - VIPConnectivityCheck > oneHourInMs) {
      return dangerColor
    }
  }

  return defaultColor
}

function getClientDataBrokerCheckColor(clientDataBrokerCheck: number, currentTime: number) {
  if(!clientDataBrokerCheck) {
    return defaultColor
  }

  if(currentTime - clientDataBrokerCheck > twentyMinutesInMs) {
    return dangerColor
  }

  return successColor
}

function getDeviceAuthenticationTokenStatusColor(authenticationTokenDate: string, currentTime: number) {
  if(!authenticationTokenDate) {
    return defaultColor
  }

  const authenticationTokenTime = new Date(authenticationTokenDate).getTime()
  const timeDiff = currentTime - authenticationTokenTime

  if(timeDiff <= eighteenHoursInMs) {
    return successColor
  }

  if(timeDiff > eighteenHoursInMs && timeDiff <= twentyTwoHoursInMs) {
    return warningcolor
  }

  return dangerColor
}

export default function StatusSection({ VIPConnectivityCheck, STBMD, clientDataBrokerCheck, lastTechnicalDataEvent, authenticationTokenDate }: StatusSectionProps) {
  const currentTime = Date.now()

  return (
    <div className="section">
      <h3 className="section-title">{t('device_checks.labels_status')}</h3>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_vip_connectivity_check')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getVIPConnectivityCheckStatusColor(VIPConnectivityCheck, STBMD, currentTime)}/>
            <span>{getDate(VIPConnectivityCheck)}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_client_data_broker_check')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getClientDataBrokerCheckColor(clientDataBrokerCheck * 1000, currentTime)}/>
            <span>{getDate(clientDataBrokerCheck)}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_device_authentication_token')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getDeviceAuthenticationTokenStatusColor(authenticationTokenDate, currentTime)}/>
            <span>{getDate(new Date(authenticationTokenDate).getTime())}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_last_technical_data_event')}:</div>
        <div className="column is-6">{DateUtils.formatDate(lastTechnicalDataEvent)}</div>
      </div>
      <div className="divider" />
    </div>
  )
}
